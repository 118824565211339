export const SUBMIT_FORM = 'registration/SUMBIT_FORM';
export const SUBMIT_FORM_SUCCESS = 'registration/SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_FAILURE = 'registration/SUBMIT_FORM_FAILURE';
export const SUBMIT_ACTIVATION_TOKEN = 'registration/SUBMIT_ACTIVATION';
export const SUBMIT_ACTIVATION_TOKEN_SUCCESS =
    'registration/SUBMIT_ACTIVATION_SUCCESS';
export const SUBMIT_ACTIVATION_TOKEN_FAILURE =
    'registration/SUBMIT_ACTIVATION_FAILURE';
export const RESEND_ACTIVATION_EMAIL = 'registration/RESEND_ACTIVATION_EMAIL';
export const RESEND_ACTIVATION_EMAIL_SUCCESS =
    'registration/RESEND_ACTIVATION_EMAIL_SUCCESS';
export const RESEND_ACTIVATION_EMAIL_FAILURE =
    'registration/RESEND_ACTIVATION_EMAIL_FAILURE';
export const LOCATION_CHANGE = 'registration/LOCATION_CHANGE';
export const SET_REDIRECT_URL = 'registration/SET_REDIRECT_URL';
export const SET_REFERRAL_CODE_ACTIVE = 'registration/SET_REFERRAL_CODE_ACTIVE';
export const SET_REFERRAL_CODE_PASSIVE =
    'registration/SET_REFERRAL_CODE_PASSIVE';

export const submitForm = ({
    salutation,
    username,
    password,
    passwordConfirmation,
    firstName,
    lastName,
    pharmacyId,
    identificationNumber,
    email,
    termsOfUse,
    privacyPolicy,
    sharePoints,
    newsletter,
    referralCode,
}) => ({
    type: SUBMIT_FORM,
    payload: {
        userData: {
            salutation,
            username,
            password,
            email,
            password_confirmation: passwordConfirmation,
            first_name: firstName,
            last_name: lastName,
            pharmacy_id: pharmacyId,
            identification_number: identificationNumber,
            terms_of_use: termsOfUse,
            privacy_policy: privacyPolicy,
            share_points: sharePoints,
            newsletter_opt_in: newsletter,
            referral_code: referralCode,
        },
    },
});

export const submitFormSuccess = () => ({
    type: SUBMIT_FORM_SUCCESS,
});

export const submitFormFailure = (error) => ({
    type: SUBMIT_FORM_FAILURE,
    payload: {error},
});

export const submitActivationTokenSuccess = (userData) => ({
    type: SUBMIT_ACTIVATION_TOKEN_SUCCESS,
    payload: {
        userData,
    },
});

export const submitActivationTokenFailure = (error) => ({
    type: SUBMIT_ACTIVATION_TOKEN_FAILURE,
    payload: {error},
});

export const resendActivationEmail = (identifier) => ({
    type: RESEND_ACTIVATION_EMAIL,
    payload: {identifier},
});

export const resendActivationEmailSuccess = () => ({
    type: RESEND_ACTIVATION_EMAIL_SUCCESS,
});

export const resendActivationEmailFailure = (error) => ({
    type: RESEND_ACTIVATION_EMAIL_FAILURE,
    payload: {error},
});

export const setRedirectUrl = (url) => ({
    type: SET_REDIRECT_URL,
    payload: {url},
});

export const setReferralCodeActive = () => ({
    type: SET_REFERRAL_CODE_ACTIVE,
});
export const setReferralCodePassive = () => ({
    type: SET_REFERRAL_CODE_PASSIVE,
});
